<script>
import { VclList } from 'vue-content-loading';

export default {
  components: {
    VclList
  },
  props: {

  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/dashboard/server-stats', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          if(data.status) {
            this.stats = data.stats;
            this.total = this.stats.total;
          } else {
            this.hasData = false;
          }
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    addTotal() {
      this.total++;
    },
    subTotal() {
      this.total--;
    }
  },
  async created() {
    this.getData();
    this.$socket.client.on('gsm:player:create', this.addTotal);
    this.$socket.client.on('gsm:player:destruct', this.subTotal);
  },
  async destroyed() {
    this.$socket.client.off('gsm:player:create', this.addTotal);
    this.$socket.client.off('gsm:player:destruct', this.subTotal);
  },
  data() {
    return {
      ready: false,
      hasData: true,
      error: false,
      stats: {},
      total: 0
    }
  }
};
</script>

<template>
  <div v-if="ready && hasData" class="row">
    <div class="col-xl-12">
      <div class="row">
        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <div class="avatar-xs mr-3">
                    <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="fad fa-users"></i>
                    </span>
                </div>
                <h5 class="font-size-14 mb-0">{{ $t('dashboard.stats.servers.total_players.title') }}</h5>
              </div>
              <div class="text-muted mt-4">
                <h4>
                  {{ $n(total) }}
                </h4>
                <div class="d-flex">
                  <span class="text-truncate">{{ $t('dashboard.stats.servers.total_players.description') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <div class="avatar-xs mr-3">
                    <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="fad fa-user-chart"></i>
                    </span>
                </div>
                <h5 class="font-size-14 mb-0">{{ $t('dashboard.stats.servers.unique_players.title') }}</h5>
              </div>
              <div class="text-muted mt-4">
                <h4>
                  {{ $n(stats.unique.value) }}
                  <template v-if="stats.unique.deviation > 0">
                    <i class="fal fa-chevron-up ml-1 text-success"></i>
                  </template>
                  <template v-else-if="stats.unique.deviation < 0">
                    <i class="fal fa-chevron-down ml-1 text-danger"></i>
                  </template>
                </h4>
                <div class="d-flex">
                  <span class="badge font-size-12" :class="{'badge-soft-warning': stats.unique.deviation === 0, 'badge-soft-danger': stats.unique.deviation < 0, 'badge-soft-success': stats.unique.deviation > 0}">
                    <template v-if="stats.unique.deviation === 0">
                      ~
                    </template>
                    <template v-else-if="stats.unique.deviation > 0">
                      +
                    </template>
                    {{ $n(stats.unique.deviation) }}%
                  </span>
                  <span class="ml-2 text-truncate">{{ $t('dashboard.stats.servers.from_yesterday') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <div class="avatar-xs mr-3">
                    <span
                        class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                    >
                      <i class="fad fa-user-plus"></i>
                    </span>
                </div>
                <h5 class="font-size-14 mb-0">{{ $t('dashboard.stats.servers.new_players.title') }}</h5>
              </div>
              <div class="text-muted mt-4">
                <h4>
                  {{ $n(stats.new.value) }}
                  <template v-if="stats.new.deviation > 0">
                    <i class="fal fa-chevron-up ml-1 text-success"></i>
                  </template>
                  <template v-else-if="stats.new.deviation < 0">
                    <i class="fal fa-chevron-down ml-1 text-danger"></i>
                  </template>
                </h4>
                <div class="d-flex">
                  <span class="badge font-size-12" :class="{'badge-soft-warning': stats.new.deviation === 0, 'badge-soft-danger': stats.new.deviation < 0, 'badge-soft-success': stats.new.deviation > 0}">
                    <template v-if="stats.new.deviation === 0">
                      ~
                    </template>
                    <template v-else-if="stats.new.deviation > 0">
                      +
                    </template>
                    {{ $n(stats.new.deviation) }}%
                  </span>
                  <span class="ml-2 text-truncate">{{ $t('dashboard.stats.servers.from_yesterday') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
  </div>
  <div v-else-if="error" class="row">
    <div class="col-xl-12">
      <div class="row">
        <div class="col-sm-12">
          <div class="card border border-danger">
            <div class="card-body">
              <h5 class="text-danger text-center">{{ $t('dashboard.stats.error') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="!ready" class="row">
    <div class="col-xl-12">
      <div class="row">
        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <vcl-list :height="100"></vcl-list>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <vcl-list :height="100"></vcl-list>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <vcl-list :height="100"></vcl-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
